exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-confidentiality-js": () => import("./../../../src/pages/about/confidentiality.js" /* webpackChunkName: "component---src-pages-about-confidentiality-js" */),
  "component---src-pages-about-cookies-js": () => import("./../../../src/pages/about/cookies.js" /* webpackChunkName: "component---src-pages-about-cookies-js" */),
  "component---src-pages-about-legal-js": () => import("./../../../src/pages/about/legal.js" /* webpackChunkName: "component---src-pages-about-legal-js" */),
  "component---src-pages-about-security-js": () => import("./../../../src/pages/about/security.js" /* webpackChunkName: "component---src-pages-about-security-js" */),
  "component---src-pages-about-terms-js": () => import("./../../../src/pages/about/terms.js" /* webpackChunkName: "component---src-pages-about-terms-js" */),
  "component---src-pages-ask-for-demo-js": () => import("./../../../src/pages/ask-for-demo.js" /* webpackChunkName: "component---src-pages-ask-for-demo-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-follow-discord-js": () => import("./../../../src/pages/follow/discord.js" /* webpackChunkName: "component---src-pages-follow-discord-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-resources-blog-js": () => import("./../../../src/pages/resources/blog.js" /* webpackChunkName: "component---src-pages-resources-blog-js" */),
  "component---src-pages-resources-community-js": () => import("./../../../src/pages/resources/community.js" /* webpackChunkName: "component---src-pages-resources-community-js" */),
  "component---src-pages-resources-help-js": () => import("./../../../src/pages/resources/help.js" /* webpackChunkName: "component---src-pages-resources-help-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-partners-js": () => import("./../../../src/pages/resources/partners.js" /* webpackChunkName: "component---src-pages-resources-partners-js" */),
  "component---src-pages-resources-partnership-js": () => import("./../../../src/pages/resources/partnership.js" /* webpackChunkName: "component---src-pages-resources-partnership-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-solutions-teams-hr-js": () => import("./../../../src/pages/solutions/teams/hr.js" /* webpackChunkName: "component---src-pages-solutions-teams-hr-js" */),
  "component---src-pages-solutions-teams-it-js": () => import("./../../../src/pages/solutions/teams/it.js" /* webpackChunkName: "component---src-pages-solutions-teams-it-js" */),
  "component---src-pages-solutions-teams-js": () => import("./../../../src/pages/solutions/teams.js" /* webpackChunkName: "component---src-pages-solutions-teams-js" */),
  "component---src-pages-solutions-teams-operation-js": () => import("./../../../src/pages/solutions/teams/operation.js" /* webpackChunkName: "component---src-pages-solutions-teams-operation-js" */),
  "component---src-pages-solutions-teams-other-js": () => import("./../../../src/pages/solutions/teams/other.js" /* webpackChunkName: "component---src-pages-solutions-teams-other-js" */),
  "component---src-pages-solutions-teams-project-js": () => import("./../../../src/pages/solutions/teams/project.js" /* webpackChunkName: "component---src-pages-solutions-teams-project-js" */),
  "component---src-pages-subscription-policies-js": () => import("./../../../src/pages/subscription/policies.js" /* webpackChunkName: "component---src-pages-subscription-policies-js" */),
  "component---src-pages-subscription-terms-js": () => import("./../../../src/pages/subscription/terms.js" /* webpackChunkName: "component---src-pages-subscription-terms-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-20240611-index-en-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/20240611/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-20240611-index-en-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-20240611-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/20240611/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-20240611-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-20240927-index-en-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/20240927/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-20240927-index-en-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-20240927-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/20240927/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-20240927-index-mdx" */)
}

